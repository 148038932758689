import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';
import ThreadCard from './ThreadCard';
import SampleContent from './SampleContent';
import HighlightCard from './HighlightCard';
import rangy from 'rangy';
import rangySerializer from 'rangy/lib/rangy-serializer';

//holds all content. Actual html. in SampleContent
class Page extends Component {
    constructor () {
        super();
        this.state = {
            mouseX: 0,
            mouseY: 0
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMouse = this.handleMouse.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.progressScroll = 0;
        this.progressMouse = 0;
        this.docHeight = 0;
    }

    //bind event listeners
    componentDidMount() {
        //for progress map
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', this.handleMouse);
        this.docHeight = (document.height !== undefined) ? document.height : document.body.offsetHeight;

        window.addEventListener('mouseup', this.handleSelect);
    }

    //thread card open/close
    actOnAnchor(anchorId) {
        //calls redux action with mouse coords that are set in _onMouseMove
        //TODO: refactor to combine sources: these x/y with event listeners.
        this.props.threadCardOperator(anchorId, { x: this.state.mouseX, y: this.state.mouseY });
    }

    //update scroll position
    handleScroll() {
        var trueY = (window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop);
        this.progressScroll = trueY
        this.props.updateScroll((this.progressScroll + this.progressMouse)/this.docHeight*100);
    }

    //update mouse position
    handleMouse(e) {
        var mouseY = e.clientY;
        this.progressMouse = mouseY
        this.props.updateScroll((this.progressScroll + this.progressMouse)/this.docHeight*100);
    }

    //get text selected and update redux store
    handleSelect(e) {
        const selectedText = rangy.getSelection().toString();
        if (selectedText && selectedText.trim()) {
            this.props.updateSelection({
                serialized: rangySerializer.serializeSelection(),
                existsSelection: true,
                selectedText: selectedText,
                selectionX: this.state.mouseX,
                selectionY: this.state.mouseY,
            });
        }
    }

    //mouse listener for annotation card spawn
    _onMouseMove(e) {
        var w = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('html')[0].clientWidth;
        var rawX = e.clientX;
        if (rawX > w - 420-64) {
            rawX = w - 420-64;
        }
        var rawY = e.clientY + (window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop) + 16;
        this.setState({
            mouseX: rawX,
            mouseY: rawY
        });
    }


    renderHighlightFlow() {
        if(this.props.selection.existsSelection) {
            return <HighlightCard
                       selection={this.props.selection}

            />
        }
    }

    render() {
        return (
            <div className="contentholder" onMouseMove={this._onMouseMove.bind(this)}  >
                <SampleContent
                    handleClick={(anchorId) => this.actOnAnchor(anchorId)}
                />
                {_.map(this.props.threadCards, thread => {
                     if (thread.openState) return <ThreadCard thread={thread} position={thread.position} key={Math.random()}/>
                })}
                {this.renderHighlightFlow()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const threadCards = state.threadCards;
    const selection = state.selection;
    return { threadCards, selection };
};

export default connect(mapStateToProps, actions)(Page);
