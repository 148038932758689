import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';

import icons from '../data/icons';

class HighlightCard extends Component {
    componentWillMount() {
        this.props.auth();
        this.props.setPage('cuddlebot')
        this.props.fetchUser();
    }
    updateFocus() {
        this.props.updateHighlightFocusOnCard(true);
    }

    deployClose() {
        console.log("close");
        this.props.closeSelectionCard();
    }
    handleEnter(event) {
        if (event.key !== 'Enter') {
            return;
        }
        var comment = event.target.value.trim()
        if (!comment) {
            return;
        }
        let userInfo = this.props.user[this.props.authData.user.uid];
        if (!userInfo || !userInfo.name) {
            var name = prompt('What do we call you?')
            if (name) {
                name = name.trim()
            }
            if (!name) {
                return;
            }
            this.props.setUserName(name);
        }
        this.props.createComment(this.props.page.ref, event.target.value, this.props.selection.selectedText, this.props.selection.serialized)
        this.deployClose();
    }

    render() {
        return (
            <div
                className="highlightCard"
                style={{
                    left: this.props.selection.selectionX,
                    top: this.props.selection.selectionY
                }}
            >
                <div className="highlightCardTopBar">
                    <div><p className="highlightCardSubhead">CREATE NEW COMMENT</p></div>
                    <div onClick={this.deployClose.bind(this)}>
                        <svg className="icon_close" viewBox="0 0 24 24" width="20px" height="20px">
                            <path d={icons.close.svg}/>
                        </svg>
                    </div>
                </div>
                <p><span className="commentTextContext">"{this.props.selection.selectedText}"</span></p>
                <input className="commentReplyInput" type="text" name="firstname"
                       placeholder="What's on your mind?" onKeyDown={this.handleEnter.bind(this)}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const commentCreate = state.commentCreate;
    const selection = state.selection;
    const page = state.page;
    const user = state.user;
    const authData = state.authData
    return { selection, commentCreate, page, user, authData };
};

export default connect(mapStateToProps, actions)(HighlightCard);
