import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';
import ThreadCard from './ThreadCard';
import SampleContent from './SampleContent';
import ProgressMap from './ProgressMap';
import DescribeVerticalPane from './DescribeVerticalPane';
import DescribeWidget from './DescribeWidget';
import rangy from 'rangy/lib/rangy-core.js';
import rangyHighlight from 'rangy/lib/rangy-highlighter';
import rangyClassApplier from 'rangy/lib/rangy-classapplier';
import rangyTextRange from 'rangy/lib/rangy-textrange';
import rangySerializer from 'rangy/lib/rangy-serializer';
import DescribeSection from './DescribeSection';

//holds all content. Actual html. in SampleContent
class DescribePageB extends Component {
    constructor () {
        super();
        this.state = {
            mouseX: 0,
            mouseY: 0
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMouse = this.handleMouse.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.progressScroll = 0;
        this.progressMouse = 0;
        this.docHeight = 0;
    }

    componentWillMount() {
        if (!this.props.page.id) {
            this.props.setPageAndFetch('cuddlebotB')
        }
    }

    //bind event listeners
    componentDidMount() {
        //for progress map
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', this.handleMouse);
        this.docHeight = (document.height !== undefined) ? document.height : document.body.offsetHeight;

        window.addEventListener('mouseup', this.handleSelect);
        //this.renderHighlights()
    }

    //thread card open/close
    actOnAnchor(anchorId) {
        //calls redux action with mouse coords that are set in _onMouseMove
        //TODO: refactor to combine sources: these x/y with event listeners.
        this.props.threadCardOperator(anchorId, { x: this.state.mouseX, y: this.state.mouseY });
    }

    //update scroll position
    handleScroll() {
        var trueY = (window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop);
        this.progressScroll = trueY
        this.props.updateScroll((this.progressScroll + this.progressMouse)/this.docHeight*100);
    }

    //update mouse position
    handleMouse(e) {
        var mouseY = e.clientY;
        this.progressMouse = mouseY
        this.props.updateScroll((this.progressScroll + this.progressMouse)/this.docHeight*100);
    }

    //get text selected and update redux store
    handleSelect(e) {
        const selectedText = rangy.getSelection().toString();
        if (selectedText && selectedText.trim()) {
            this.props.updateSelection({
                serialized: rangySerializer.serializeSelection(),
                existsSelection: true,
                selectedText: selectedText,
                selectionX: this.state.mouseX,
                selectionY: this.state.mouseY,
            });

            this.props.updateDescribeSelectionExists();
            this.props.updateDescribeSelection(selectedText);
        }
    }

    //mouse listener for annotation card spawn
    _onMouseMove(e) {
        return;
        /* var w = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('html')[0].clientWidth;
        var rawX = e.clientX;
        if (rawX > w - 420-64) {
            rawX = w - 420-64;
        }
        var rawY = e.clientY + (window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop) + 16;
        this.setState({
            mouseX: rawX,
            mouseY: rawY
        });*/
    }

    renderHighlights() {
        console.log('render')
        // TODO: find out why this doesn't work!!
        let highlighter = rangy.createHighlighter();
        highlighter.addClassApplier(rangy.createClassApplier("describeHighlight"));
        Object.values(this.props.threadData.threads).forEach(v => {
            if (v.selection) {
                let selection = rangySerializer.deserialize(v.selection)
                //let el = document.createElement('span');
                //el.classList.add('describeHighlight')
                //selection.getRangeAt(0).surroundContents(el)
                highlighter.highlightSelection("describeHighlight", {selection});
            }
        })
    }

    render() {
        return (
            <div className="contentholder" onMouseMove={this._onMouseMove.bind(this)}  >
                <SampleContent
                    handleClick={(anchorId) => this.actOnAnchor(anchorId)}
                />
                <DescribeSection />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const selection = state.selection;
//    const describePane = state.describePane;
    const page = state.page;
    return { selection, page };
};

export default connect(mapStateToProps, actions)(DescribePageB);
