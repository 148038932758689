import ReactGA from '../../react-ga';
// Reducer that produces a OpenCards - an object containing the ids of open cards


const INITIAL_STATE = {
    existsSelection: false,
    selectedText: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'update_describe_selection_exists':
            return { ...state, "existsSelection": true };
        case 'update_describe_selection':
            ReactGA.event({
                category: 'Selection',
                action: 'User selected text',
            });

            console.log(action.payload);
            return { ...state, "selectedText": action.payload };
        case 'close_describe_selection':
            return { ...state, "existsSelection": false };
        default:
            return state;
    }
};
