import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import ReactGA from '../react-ga';
import '../App.css';

import icons from '../data/icons';


class describeCommentBox_Bottom extends Component {
    constructor() {
        super()
        this.state = {
            value: "",
            nameValue: "anonymous",
            changeName: false
        }
    }
    handleEnter(event) {
        if (event.key !== 'Enter') {
            return;
        }
        event.preventDefault();
        var comment = this.state.value.trim()
        if (!comment) {
            return;
        }
        /*if (!this.getName()) {
            var name = prompt('What do we call you?')
            if (name) {
                name = name.trim()
            }
            if (!name) {
                return;
            }
            this.props.setUserName(name);
        }*/
        if (this.state.nameValue === "anonymous") {
            this.props.setUserName(this.getName());
        }
        else {
            this.props.setUserName(this.state.nameValue);
        }
        this.props.createComment(event.target.value, this.props.selection, null)
        this.updateText({value: ''});
        this.props.closeDescribeSelection();
    }
    getName() {
        if (!this.props.authData || !this.props.authData.user) {
            return 'anonymous';
        }
        let userInfo = this.props.user[this.props.authData.user.uid];
        if (!userInfo || !userInfo.name) {
            return 'anonymous';
        }
        return userInfo.name;
    }

    updateText(tgt) {
        const { value } = tgt;
        this.setState({ value: value });
    }

    updateName(tgt) {
        const { value } = tgt;
        this.setState({ nameValue: value });
    }

    displayHighlight() {
        if (this.props.describeSelection.existsSelection) {
            return <span>"{this.props.describeSelection.selectedText}"</span>
        }
        else {
            return <span className="describeLight">Highlight a passage to tag your comment...</span>
        }
    }

    renderHighlightDelete() {
        if (this.props.describeSelection.existsSelection) {
            return <p className="describeCommentReply" onClick={this.props.closeDescribeSelection.bind(this) } >Remove Highlight</p>
        }
    }

    toggleChangeName() {
        this.setState({ changeName: !this.state.changeName });
    }

    handleNameEnter(event) {
        ReactGA.event({
            category: 'Edit',
            action: 'Set name',
        });
        if (event.key !== 'Enter') {
            return;
        }
        var name = this.state.nameValue.trim()
        if (!name) {
            return;
        }
        this.props.setUserName(name);
        this.setState({ nameValue: name });
        if (this.state.changeName) {
            this.toggleChangeName();
        }
        console.log("xx "+this.getName());
    }

    renderNameBox() {
        if (this.state.changeName) {
            return (
                <textarea
                    onKeyDown={this.handleNameEnter.bind(this)}
                    className="describeCommentNamearea"
                    placeholder={this.getName()}
                    onChange = {evt => this.updateName(evt.target)}
                ></textarea>
            )
        }
        else {
            return (
                <p className="describeBoldText midBMargin">&nbsp;{this.props.userName}&nbsp;<a className="describeCommentReply" onClick={this.toggleChangeName.bind(this)}>Change</a></p>
            )
        }
    }

    render() {
        return (
            <div className={"describeCommentBoxBottom" + (this.props.describePane ? " describeCommentBoxTopBorder" : "") }>
                <div className="describeCommentingHolder">
                    <p className="describeBoldText midBMargin">Commenting as</p>
                    {this.renderNameBox()}
                </div>
                <div className="describeCommentHighlightHolder">
                    <p className="describeSmText"><span className="describeHighlight">{this.displayHighlight()}</span></p>
                    {this.renderHighlightDelete()}
                </div>
                <div className="describeCommentEntry">
                    <textarea
                        onKeyUp={this.handleEnter.bind(this)}
                        className="describeCommentTextarea"
                        value={this.state.value}
                        placeholder="Post a comment..."
                        onChange = {
                            evt => this.updateText(evt.target)
                        }
                    >
                    </textarea>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const selection = state.selection;
    const describePane = state.describePane;
    const describeSelection = state.describeSelection;
    const authData = state.authData
    const user = state.user;
    const page = state.page;

    var userName;

    if (!authData || !authData.user) {
        userName = 'anonymous';
    }
    else {
        let userInfo = user[authData.user.uid];
        if (!userInfo || !userInfo.name) {
            userName = 'anonymous';
        }
        else {
            userName = userInfo.name;
        }
    }

    return { describePane, describeSelection, selection, user, authData, page, userName };
};

export default connect(mapStateToProps, actions)(describeCommentBox_Bottom);
