import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';
import * as actions from '../redux/actions';

//Like Reply Share Time. TODO add functionality
class DescribeThreadActions extends Component {
    constructor() {
        super()
        this.state = {
            replyValue: "",
            replyOpen: false
        }
    }

    updateText(tgt) {
        const { value } = tgt;
        this.setState({ replyValue: value });
    }

    handleEnter(event) {
        if (event.key !== 'Enter') {
            return;
        }
        var comment = this.state.replyValue.trim()
        if (!comment) {
            return;
        }
        if (!this.getName()) {
            var name = prompt('What do we call you?')
            if (name) {
                name = name.trim()
            }
            if (!name) {
                return;
            }
            this.props.setUserName(name);
        }

        let parentId = this.props.isHead ? this.props.commentRef.id : this.props.parentId;
        this.props.createComment(this.state.replyValue, null, parentId)
        this.updateText({value: ''});
        this.setState({replyOpen: false});
    }

    getName() {
        if (!this.props.authData || !this.props.authData.user) {
            return '';
        }
        let userInfo = this.props.user[this.props.authData.user.uid];
        if (!userInfo || !userInfo.name) {
            return '';
        }
        return userInfo.name;
    }

    componentWillMount() {

    }
    /** Don't allows this unless this user created the comment or is admin. */
    deleteComment() {
        this.props.deleteComment(this.props.commentRef, this.props.isHead ? undefined : this.props.parentId)
    }
    replyComment() {
        //this.props.replyOpen(this.props.threadId);
        this.setState({replyOpen: true});
    }

    renderReplyForm() {
        if (this.state.replyOpen) {
            return (
                <textarea
                    onKeyUp={this.handleEnter.bind(this)}
                    className="describeCommentTextarea"
                    value={this.state.replyValue}
                    placeholder="What's on your mind?"
                    onChange = {
                        evt => this.updateText(evt.target)
                    }
                >
                </textarea>
            )
        }
    }

    timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
          return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
          return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
          return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
          return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
          return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    render() {
        return (
            <div className="describeReplyHolder">
                <div className="commentMetaFunctions">
                    <a className="describeCommentReply" onClick={this.replyComment.bind(this)}>Reply </a>
                    {this.props.isOwned && !this.props.isDeleted &&
                    <a className="describeCommentReply" onClick={this.deleteComment.bind(this)}>Delete </a>
                    }
                    <p className="commentTime"></p>

                </div>
                {this.renderReplyForm()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const user = state.user;
    const authData = state.authData;
    return { user, authData };
};

export default connect(mapStateToProps, actions)(DescribeThreadActions);
