
const INITIAL_STATE = {
    ref: null,
    id: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'set_page':
            return action.payload;
        default:
            return state;
    }
};
