import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Dactions from '../redux/actions_describe';
import '../App.css';
import ReactGA from '../react-ga';

import icons from '../data/icons';
import DescribeCommentBox from './DescribeCommentBox';
import DescribeThreadList from './DescribeThreadList';


class DescribeWidget extends Component {

    toggle() {
        ReactGA.event({
            category: 'Navigation',
            action: 'Toggle describe widget',
        });
        (this.props.describePane) ? this.props.collapsePane() : this.props.expandPane();
    }

    renderToggle() {
        let count = this.getCount();
        if (this.props.describePane) {
            return <p className="describeSmText noBMargin describeExpandColor describeCommentReply defaultCursor">{count} comments [-]</p>
        }
        else {
            return <p className="describeSmText noBMargin describeExpandColor describeCommentReply defaultCursor">{count} comments [+]</p>
        }
    }
    getCount() {
        let count = 0;
        Object.values(this.props.threadData.threads).forEach(v => {
            count++;
            Object.values(v.replies).forEach(v => {
                count++;
            })
        })
        return count;
    }
    render() {
        return (
            <div
                className={"describeWidget" + (this.props.describePane ? " describeWidgetExpanded" : "") }
                style={{ right: "32px" }}
            >
                <div className="describePaneElements">
                    <div className="describePaneToggler" onClick={this.toggle.bind(this)}>
                        {this.renderToggle()}

                    </div>
                    <DescribeCommentBox />
                    {(this.props.describePane ? <DescribeThreadList /> : null)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const describePane = state.describePane;
    const threadData = state.threadData;
    return { describePane, threadData };
};

export default connect(mapStateToProps, Dactions)(DescribeWidget);
