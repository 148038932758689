import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import firebase from './firebase';
import reducers from './redux/reducers';
import './App.css';
import Page from './components/Page'
import PageC from './components/PageC'
import ReactPixel from 'react-facebook-pixel';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import DescribePageA from './components/DescribePageA';
import DescribePageB from './components/DescribePageB';
import ReactGA from './react-ga';

const urlPropsQueryConfig = {
    variant: { type: UrlQueryParamTypes.number, queryParam: 'v' },
};

/** Enable hot reload of reducers.
 * https://github.com/reduxjs/react-redux/releases/tag/v2.0.0
 */
function configureStore(initialState) {
    const store = createStore(reducers, initialState, applyMiddleware(ReduxThunk));

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./redux/reducers', () => {
            const nextRootReducer = require('./redux/reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

const store = configureStore({});

class App extends Component {

    static propTypes = {
        // URL props are automatically decoded and passed in based on the config
        variant: PropTypes.number,
    }

    static defaultProps = {
        variant: undefined,
    }
	componentWillMount() {
        // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        //const advancedMatching = { em: 'some@email.com' };


        const options = {
        // set pixel's autoConfig
        autoConfig: true,
        // enable logs
        debug: true,
        };

        ReactPixel.init('915362685325559', options);
        // For tracking page view
        ReactPixel.pageView();
        // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        //ReactPixel.track(event, data)
        // For tracking custom events
        //ReactPixel.trackCustom(event, data)


    document.title = 'describe'
    this.state = {
      variant: undefined
    }
	}
    hash(str) {
        return str.split('').map(v=>v.charCodeAt(0)).reduce((a,v)=>a+((a<<7)+(a<<3))^v)
    }
    componentDidMount() {
        if (typeof(this.props.variant) === 'number') {
            this.setState({ variant: this.props.variant })
        } else {
            fetch('https://api.ipify.org/')
                .then(response => response.text())
                .then(data => {
                  this.setState({ variant: ((this.hash(data) % 3) + 3) % 3 });
                });

        }
    }
    serveVariant() {
        if (!this.state) {
            return (<Page/>)
        }
        switch(this.state.variant) {
            case 0:
                ReactGA.pageview('PageA')
                return (<DescribePageA/>)
            case 1:
                ReactGA.pageview('PageB')
                return (<DescribePageB/>)
            case 2:
                ReactGA.pageview('PageC')
                return (<PageC/>)
            default:
                ReactGA.pageview('Page')
                console.error('poop')
                return (<Page/>)
        }
    }

	render() {
        console.log(this.state && this.state.variant)
		return (
			<div className="App">
				<Provider store={store}>
					{this.serveVariant()}
				</Provider>
			</div>
		);
	}
}

export default addUrlProps({urlPropsQueryConfig})(App);
