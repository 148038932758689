// Reducer that produces a OpenCards - an object containing the ids of open cards


const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'thread_card_operate':
            const threadInfo = state[action.payload.threadId];
            if (threadInfo && threadInfo.openState) {
                return {...state, [action.payload.threadId]: { 
                    threadId: action.payload.threadId, 
                    openState: false, 
                    position: action.payload.position 
                } };
            }
            else {
                return {...state, [action.payload.threadId]: { 
                    threadId: action.payload.threadId, 
                    openState: true, 
                    position: action.payload.position 
                } };
            }
        default:
            return state;
    }
};
