import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';

import threadData from "../data/threadData.json";

class ProgressThreadIcon extends Component {
    constructor() {
        super();
        this.docHeight = 0;
    }

    componentDidMount() {
        this.docHeight = (document.height !== undefined) ? document.height : document.body.offsetHeight;
    }

    actOnAnchor(anchorId) {
        console.log(this.props.anchorPositions);
        var dispY = 24 + this.props.anchorPositions[this.props.anchor.name].position * this.docHeight / 100;
        var dispX = this.props.anchorPositions[this.props.anchor.name].horizontal;
        this.props.threadCardOperator(anchorId, { x: dispX, y: dispY });
    }

    render() {

        var thread = threadData.filter((threadObj) => {
            return threadObj.anchorId === this.props.anchor.name;
        });
        
        return (
            <div>
                <div 
                    className="progressThreadIcon" 
                    style={{ top: this.props.anchor.position+"vh" }} 
                    onClick={this.actOnAnchor.bind(this, this.props.anchor.name)}
                >
                    <img className="progressThreadImg" src={thread[0].comments[0].avatar} />
                </div>
                <div className="progressThreadTag" style={{ top: this.props.anchor.position+"vh" }} >
                    <p className="progressThreadTagText">{thread[0].comments[0].content}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const scrollPercent = state.scrollPercent;
    const threadCards = state.threadCards;
    //console.log(threadCards);
    const anchorPositions = state.anchorPositions.anchorPositions;
    return { scrollPercent, anchorPositions };
};

export default connect(mapStateToProps, actions)(ProgressThreadIcon);