// Reducer that produces a OpenCards - an object containing the ids of open cards


const INITIAL_STATE = 0;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'update_scroll':
            return action.payload;
        default:
            return state;
    }
};