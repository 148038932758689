// combines reducers into app store

import { combineReducers } from 'redux';
import OpenCardsReducer from './OpenCardsReducer';
import ScrollReducer from './ScrollReducer';
import AnchorPositionReducer from './AnchorPositionReducer';
import SelectionReducer from './SelectionReducer';
import DescribePaneReducer from './DescribePaneReducer';
import DescribeSelectionReducer from './DescribeSelectionReducer';
import CommentCreateReducer from './CommentCreateReducer';
import AuthReducer from './AuthReducer';
import PageReducer from './PageReducer';
import UserReducer from './UserReducer';
import ThreadDataReducer from './ThreadDataReducer';
import ReplyActiveReducer from './ReplyActiveReducer';

export default combineReducers({
    user: UserReducer,
    page: PageReducer,
    authData: AuthReducer,
    commentCreate: CommentCreateReducer,
    threadCards: OpenCardsReducer,
    scrollPercent: ScrollReducer,
    anchorPositions: AnchorPositionReducer,
    selection: SelectionReducer,
    describePane: DescribePaneReducer,
    describeSelection: DescribeSelectionReducer,
    threadData: ThreadDataReducer,
    replyStates: ReplyActiveReducer,
});
