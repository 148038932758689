// Reducer that produces a OpenCards - an object containing the ids of open cards


const INITIAL_STATE = {
    existsSelection: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'update_selection':
            console.log(action);
            return action.payload.selection;
        case 'close_selection':
            return {...state, "existsSelection": false};
        default:
            return state;
    }
};