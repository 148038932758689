import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';
import SampleContent from './SampleContent';
import { FacebookProvider, Comments } from 'react-facebook';

//holds all content. Actual html. in SampleContent
class PageA extends Component {
  constructor () {
    super();
  }

  render() {
    return (
      <FacebookProvider appId="193355378208482">
        <div className="contentholder">
          <SampleContent />
          <div className="contentSpacing">
              <Comments href={window.location.href} />
          </div>
        </div>
      </FacebookProvider>
    );
  }
}

export default PageA;
