import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';
var __html = require('./Cuddlebot.html');
var template = { __html: __html };

class SampleContent extends Component {
  componentWillMount() {
    document.title = 'Ben Mann - Cuddlebot'
  }
  render() {
    return (
      <div id="contentDiv">
        <div className="contentSpacing" dangerouslySetInnerHTML={template}>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(SampleContent);
