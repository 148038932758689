import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';

import threadData from "../data/threadData.json";
import ThreadCardComment from './ThreadCardComment';

class ThreadCard extends Component {
    constructor () {
        super();
        this.state = { 
            threadId: "",
            comments: [],
            context: ""
        };
    }

    componentWillMount() {
        var found = threadData.find((element) => {
            return element.anchorId === this.props.thread.threadId;
        });
        this.setState({ comments: found.comments, context: found.context, threadId: this.props.thread.threadId });
    }

    displayHead() {
        return (
            <div>
                <ThreadCardComment
                    threadId={this.state.threadId}
                    avatar={this.state.comments[0].avatar}
                    author={this.state.comments[0].author}
                    context={this.state.context}
                    content={this.state.comments[0].content}
                    isHead={true}
                />
            </div>
        );
    }

    displayChildren() {
        var restComments = this.state.comments.slice(1);
        return restComments.map((comment) => {
            return (
                <div className="childComment" key={comment.time}>
                    <ThreadCardComment
                        threadId={this.state.threadId}
                        avatar={comment.avatar}
                        author={comment.author}
                        context={""}
                        content={comment.content}
                        isHead={false}
                    />
                </div>
            )
        });
    }

    render() {
        return (
            <div 
                className="threadCard"
                style={{ left: this.props.position.x, top: this.props.position.y }}
            >
                {this.displayHead()}
                {this.displayChildren()}
                <div className="childComment">
                    <div className="commentHolder">
                        <div className="commentAvatar commentReplyAvatarPadding">
                            <img src="https://scontent-lax3-1.cdninstagram.com/vp/d58bf99862a7ab033b7e5c3892bfe028/5C70BB6E/t51.2885-19/s150x150/32243854_185171625474285_264624342539698176_n.jpg" className="commentAvatar" />
                        </div>
                        <div className="commentReplyBox">
                            <input className="commentReplyInput" type="text" name="firstname" placeholder="Write a reply..." />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, actions)(ThreadCard);