import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Dactions from '../redux/actions_describe';
import '../App.css';
import ReactGA from '../react-ga';

import icons from '../data/icons';
import DescribeCommentBox from './DescribeCommentBox';
import DescribeThreadList from './DescribeThreadList';
import DescribeCommentBox_Bottom from './DescribeCommentBox_Bottom';


class DescribeSection extends Component {

    renderToggle() {
        let count = this.getCount();
        return <p className="describeSmText noBMargin describeCommentReply defaultCursor">{count} comments</p>
        
    }
    getCount() {
        let count = 0;
        Object.values(this.props.threadData.threads).forEach(v => {
            count++;
            Object.values(v.replies).forEach(v => {
                count++;
            })
        })
        return count;
    }
    render() {
        return (
            <div
                className={"describeSection contentSpacing describeWidgetExpanded" }
            >
                <div className="describePaneElements">
                    <div className="describePaneToggler">
                        {this.renderToggle()}

                    </div>
                    <DescribeCommentBox_Bottom />
                    <DescribeThreadList />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const describePane = state.describePane;
    const threadData = state.threadData;
    return { describePane, threadData };
};

export default connect(mapStateToProps, Dactions)(DescribeSection);
