import ReactGA from '../../react-ga';
import firebase from '../../firebase';
import ReactPixel from 'react-facebook-pixel';

const db = firebase.firestore();
export const createComment = (comment, selection, parentId) => {
    // Start
    ReactPixel.track('ViewContent');
    ReactGA.event({
        category: 'Create Comment',
        action: 'Create Comment',
    });

    return (dispatch, getState) => {
        dispatch({
            type: 'create_comment_start',
            payload: null,
        })

        let data = {
            comment,
            author: firebase.auth().currentUser.uid,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        };
        if (parentId) {
            data.parent = parentId
        }

        if (selection && selection.existsSelection) {
            data.context = selection.selectedText;
            data.selection = selection.serialized;
        }
        const ref = getState().page.ref

        ref.collection('replies').add(data).then(_ => {
            dispatch({
                type: 'create_comment_success',
                payload: null,
            })
        }).catch(err => {
            dispatch({
                type: 'create_comment_fail',
                payload: err,
            })
        })
    }
}

export const fetchThreads = (parentRef) => {

    ReactGA.event({
        category: 'fetch threads',
        action: 'fetch threads',
    });
    if (!parentRef) {
        return {
            type: 'empty_fetch',
            payload: null,
        };
    }
    return dispatch => {
        dispatch({
            type: 'fetch_threads_start',
            payload: {threads: {}, needsInit: false},
        })
        // TODO: dispatch loading state immediately
        // TODO: also unsubscribe when component unmounts?
        let getReplies = (ref, type) => {
            ref.collection('replies').orderBy('created', 'desc').onSnapshot(querySnapshot => {
                var threads = {};
                querySnapshot.forEach(function(doc) {
                    threads[doc.ref.id] = {...doc.data(), ref: doc.ref, replies: {}}
                });
                console.log('fetched ', Object.keys(threads).length)
                dispatch({
                    type,
                    payload: threads,
                })
            });
        }
        getReplies(parentRef, 'fetch_threads')
    }
}

export const setPage = (id) => {
    ReactGA.event({
        category: 'setpage',
        action: 'setpage',
    });
    return {
        type: 'set_page',
        payload: {id, ref: db.collection('pages').doc(id)},
    }
}

export const setPageAndFetch = (id) => {
    ReactGA.event({
        category: 'setpageandfetch',
        action: 'setpageandfetch',
    });
    return (dispatch, getState) => {
        dispatch(auth());
        dispatch(fetchUser())
        dispatch(setPage(id))
        const ref = getState().page.ref
        return dispatch(fetchThreads(ref))
    }
}

export const fetchUser = (id) => {
    ReactGA.event({
        category: 'fetchuser',
        action: 'fetchuser',
    });
    if (!id && firebase.auth().currentUser) {
        id = firebase.auth().currentUser.uid;
    }
    return dispatch => {
        let actuallyFetch = (id) => {
            db.collection('users').doc(id).get().then(snap => {
                if (snap.exists) {
                    dispatch({
                        type: 'fetch_user',
                        payload: {[id]: snap.data()}
                    })
                }
            })
        }
        if (!id) {
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    actuallyFetch(user.uid)
                }
            })
        } else {
            actuallyFetch(id)
        }
    }
}


export const setUserName = (name) => {

    ReactGA.event({
        category: 'set Username',
        action: 'set Username',
    });
    const id = firebase.auth().currentUser.uid
    return dispatch => {
        db.collection('users').doc(id).set({
            name: name,
        }).then(() => {
            dispatch({
                type: 'set_user_name',
                payload: {id, name}
            })
        }).catch(err => {
            console.error('failed to set user name', err)
        });
    }
}

export const voteComment = (commentRef, vote) => {
    ReactGA.event({
        category: 'votecomment',
        action: 'votecomment',
    });
    return {
        type: 'vote_comment',
        payload: { commentRef, vote }
    }
}

export const deleteComment = (commentRef, parentId) => {
    ReactGA.event({
        category: 'deletecomment',
        action: 'deletecomment',
    });
    return (dispatch, getState) => {
        // This is a parent comment and it has children.
        if (!parentId && Object.keys(getState().threadData.threads[commentRef.id].replies).length) {
            commentRef.update({
                deleted: true,
                comment: 'Deleted'
            }).then(() => {
                dispatch({
                    type: 'soft_delete_comment',
                    payload: {commentRef, parentId}
                })
            }).catch(err => {
                console.error(err)
            })
            return;
        }
        commentRef.delete().then(() => {
            dispatch({
                type: 'delete_comment',
                payload: {commentRef, parentId}
            })
        }).catch(err => {
            console.error(err)
        })
    }
}

export const auth = () => {
    return dispatch => {
        dispatch({
            type: 'auth_start',
            payload: null,
        })
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                console.log(user.uid)
                ReactGA.set({ userId: user.uid });
                // User is signed in.
                dispatch({
                    type: 'auth_logged_in',
                    payload: { user }
                })
            } else {
                firebase.auth().signInAnonymously().then(user => {
                    dispatch({
                        type: 'auth_login',
                        payload: { user: firebase.auth().currentUser }
                    })
                }).catch(err => {
                    console.error(err, err.message)
                    dispatch({
                        type: 'auth_fail',
                        payload: null,
                    })
                }) // signInAnonymously
            } // if/else
        }); // onAuthStateChanged
    } // return
}

export const threadCardOperator = (threadId, position) => {
    //acts on OpenCardReducer
    return {
        type: 'thread_card_operate',
        payload: { threadId, position }
    };
}

export const updateScroll = (scrollPercent) => {
    return {
        type: 'update_scroll',
        payload: { scrollPercent }
    };
}

export const updateAnchors = (anchorPositions) => {
    //console.log(anchorPositions);
    return {
        type: 'update_anchors',
        payload: { anchorPositions }
    };
}

export const updateSelection = (selection) => {
    return {
        type: 'update_selection',
        payload: { selection }
    };
}

export const updateHighlighFocusOnCard = (highlightFocused) => {
    return {
        type: 'update_card_focus',
        payload: { highlightFocused }
    }
}

export const closeSelectionCard = () => {
    return {
        type: "close_selection",
        payload: null
    }
}

export const updateDescribeSelectionExists = () => {
    ReactGA.event({
        category: 'updatedescribeselectionexists',
        action: 'updatedescribeselectionexists',
    });
    return {
        type: "update_describe_selection_exists",
        payload: null
    }
}

export const updateDescribeSelection = (selection) => {
    ReactGA.event({
        category: 'updatedescribeselection',
        action: 'updatedescribeselection',
    });
    console.log(selection);
    return {
        type: "update_describe_selection",
        payload: selection
    }
}

export const closeDescribeSelection = () => {
    ReactGA.event({
        category: 'closedescribeselection',
        action: 'closedescribeselection',
    });
    return {
        type: "close_describe_selection",
        payload: null
    }
}

export const replyOpen = (threadId) => {
    ReactGA.event({
        category: 'replyopen',
        action: 'replyopen',
    });
    return {
        type: "reply_open",
        payload: { "threadId": threadId }
    }
}

export const replyClose = (threadId) => {
    ReactGA.event({
        category: 'replyclose',
        action: 'replyclose',
    });
    return {
        type: "reply_close",
        payload: { "threadId": threadId }
    }
}

export const expandPane = () => {
    ReactGA.event({
        category: 'expandpane',
        action: 'expandpane',
    });
    return {
        type: "pane_expand",
        payload: null
    }
}

export const collapsePane = () => {
    ReactGA.event({
        category: 'collapsepane',
        action: 'collapsepane',
    });
    return {
        type: "pane_collapse",
        payload: null
    }
}
