import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';
import ThreadCardCommentActions from './ThreadCardCommentActions';

import icons from '../data/icons.json';
import DescribeThreadActions from './DescribeThreadActions';

//Like Reply Share Time. TODO add functionality
class DescribeThreadComment extends Component {

    componentWillMount() {
        this.props.fetchUser(this.props.author)
    }


    actOnAnchor() {
        this.props.threadCardOperator(this.props.threadId, { x: 0, y: 0 });
    }

    renderContext() {
        if (this.props.isHead && this.props.context && this.props.context.length >= 1) {
            return (
                <p className="describeSmText">
                    <span className="describeLight">tagged </span><span className="describeHighlight">"{this.props.context}"</span>
                </p>
            );
        }
    }

    renderClose() {
        if (this.props.isHead) {
            return (
                <div className="commentCloseIcon" onClick={this.actOnAnchor.bind(this)}>
                    <svg className="icon_close" viewBox="0 0 24 24" width="20px" height="20px">
                        <path d={icons.close.svg}/>
                    </svg>
                </div>
            );
        }
    }

    isOwned() {
        return this.props.author === this.props.authData.user.uid;
    }

    timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
          return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
          return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
          return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
          return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
          return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }


    render() {
        let userInfo = this.props.user[this.props.author];

        return (
            <div className="describeCommentHolder">
                <div className="describeCommentAvatarHolder">
                    <img src={userInfo && userInfo.avatar} className="describeCommentAvatar" />
                </div>
                <div className="commentTop">
                    <div className="commentBody">
                        <p className="describeBoldText">
                            <span className="describeBoldText midBMargin">{userInfo && userInfo.name}</span>
                            <span className="commentTime">{this.props.created && this.timeSince(this.props.created.toDate())}</span>
                        </p>
                        {this.renderContext()}
                        <p className={"describeNormalText midBMargin" + (this.props.isDeleted ? ' describeDeletedText' : '') }>{this.props.content}</p>
                        <DescribeThreadActions
                            isHead={this.props.isHead}
                            isOwned={this.isOwned()}
                            isDeleted={this.props.isDeleted}
                            commentRef={this.props.commentRef}
                            created={this.props.created}
                            threadId={this.props.threadId}
                            parentId={this.props.parentId}
                            />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const user = state.user;
    const authData = state.authData;
    return { user, authData };
};

export default connect(mapStateToProps, actions)(DescribeThreadComment);
