import React, { Component } from 'react';
import '../App.css';

//Like Reply Share Time. TODO add functionality
class ThreadCardCommentActions extends Component {

    componentWillMount() {
    }

    render() {
        return (
            <div className="commentMetaFunctions">
                <p className="commentLike">LIKE</p>
                <p className="commentReply">REPLY</p>
                <p className="commentTime">5h</p>
            </div>
        );
    }
}

export default ThreadCardCommentActions;