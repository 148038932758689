import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Dactions from '../redux/actions_describe';
import '../App.css';

import icons from '../data/icons';
import DescribeThreadCard from './DescribeThreadCard';


class DescribeThreadList extends Component {
    constructor() {
        super()
        this.state = {
            value: ""
        }
    }

    updateText(tgt) {
        const { value } = tgt;
        this.setState({ value: value });
    }

    render() {
        return (
            <div className="describeThreadList">
                {Object.keys(this.props.threadData.threads).map(key => {
                     let thread = this.props.threadData.threads[key]
                     return <DescribeThreadCard key={key} thread={thread} />
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const describePane = state.describePane;
    const threadData = state.threadData;
    //console.log('aaa', threadData)
    return { describePane, threadData };
};

export default connect(mapStateToProps, Dactions)(DescribeThreadList);
