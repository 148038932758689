const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'set_user_name':
            if (state[action.payload.id]) {
                if (action.payload.name === "" || action.payload.name === null) {
                    state[action.payload.id].name = "anonymous";
                }
                else {
                    state[action.payload.id].name = action.payload.name;
                }
            } else {
                if (action.payload.name === "" || action.payload.name === null) {
                    state[action.payload.id] = {name: "anonymous"};
                }
                else {
                    state[action.payload.id] = {name: action.payload.name};
                }
            }
            return state;
        case 'fetch_user':
            return {...state, ...action.payload};
        default:
            return state;
    }
};
