import firebase from 'firebase';

// Initialize Firebase
var config = {
	apiKey: "AIzaSyCMe7isuCxRRg_GOTOEABTMP3N23UusYyo",
	authDomain: "describe-0.firebaseapp.com",
	databaseURL: "https://describe-0.firebaseio.com",
	projectId: "describe-0",
	storageBucket: "describe-0.appspot.com",
	messagingSenderId: "654366796781"
};
if (!firebase.apps.length) {
	firebase.initializeApp(config);
  const firestore = firebase.firestore();
  const settings = {/* your settings... */ timestampsInSnapshots: true};
  firestore.settings(settings);

  firestore.enablePersistence().catch(err => {
    console.error('Failed to enable firestore persistence', err);
  });
}

export default firebase;
