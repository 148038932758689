// Reducer that produces a OpenCards - an object containing the ids of open cards


const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'reply_open':
            return {...state, [action.payload.threadId]: { 
                threadId: action.payload.threadId, 
                openState: true
            }};
        case 'reply_close':
            return {...state, [action.payload.threadId]: { 
                threadId: action.payload.threadId, 
                openState: false
            }};
        default:
            return state;
    }
};
