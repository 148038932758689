const INITIAL_STATE = {
    user: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'auth_start':
            return action.payload;
        case 'auth_logged_in':
            return action.payload;
        case 'auth_login':
            return action.payload;
        case 'auth_logout':
            return action.payload;
        case 'auth_fail':
            return action.payload;
        default:
            return state;
    }
};
