import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Dactions from '../redux/actions_describe';
import '../App.css';

import icons from '../data/icons';


class DescribeVerticalPane extends Component {

    toggle() {
        this.props.collapsePane();
    }

    render() {
        return (
            <div>
                <div className="describeTogglePane">
                    <div className="describeToggler" onClick={this.toggle.bind(this)}>
                        <p className="describeNormalText">32 comments</p>
                    </div>
                </div>
            </div>
        );  
    }
}

const mapStateToProps = state => {
    const selection = state.selection;
    return { selection };
};

export default connect(mapStateToProps, Dactions)(DescribeVerticalPane);