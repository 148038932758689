import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';
import ThreadCardCommentActions from './ThreadCardCommentActions';

import icons from '../data/icons.json';

//Like Reply Share Time. TODO add functionality
class ThreadCardComment extends Component {

    componentWillMount() {
    }


    actOnAnchor() {
        this.props.threadCardOperator(this.props.threadId, { x: 0, y: 0 });
    }

    renderContext() {
        if (this.props.isHead) {
            return (
                <p className="commentTextContextModifier">
                    <span className="commentTextContext">"{this.props.context}"</span>
                </p>
            );
        }
    }

    renderClose() {
        if (this.props.isHead) {
            return (
                <div className="commentCloseIcon" onClick={this.actOnAnchor.bind(this)}>
                    <svg className="icon_close" viewBox="0 0 24 24" width="20px" height="20px">
                        <path d={icons.close.svg}/>
                    </svg>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="commentHolder">
                <div className="commentAvatar">
                    <img src={this.props.avatar} className="commentAvatar" />
                </div>
                <div className="commentTop">
                    <div className="commentBody">
                        <p className="commentTextAuthor">{this.props.author}</p>
                        {this.renderContext()}       
                        <p className="commentTextContent">{this.props.content}</p>
                        <ThreadCardCommentActions />
                    </div>
                    <div className="commentClose">
                        {this.renderClose()}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, actions)(ThreadCardComment);