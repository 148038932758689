import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';

import threadData from "../data/threadData.json";
import DescribeThreadComment from './DescribeThreadComment';

class DescribeThreadCard extends Component {
    constructor () {
        super();
        this.state = {
            threadId: "",
            comments: [],
            context: ""
        };
    }

    componentWillMount() {

        //console.log(this.props.thread)
        this.setState({
            threadId: this.props.thread.anchorId,
            context: this.props.thread.context,
            comments: this.props.thread.comments
        });
    }

    displayHead() {
        let comment = this.props.thread;
        //console.log(comment);
        return (
            <div>
                <DescribeThreadComment
                    threadId={comment.ref.path}
                    author={comment.author}
                    context={comment.context}
                    content={comment.comment}
                    created={comment.created}
                    commentRef={comment.ref}
                    isDeleted={comment.deleted}
                    isHead={true}
                />
            </div>
        );
    }

    displayChildren() {
        return Object.keys(this.props.thread.replies).map(key => {
            let comment = this.props.thread.replies[key]
            return (
                <div className="describeChildComment" key={key}>
                    <DescribeThreadComment
                        threadId={comment.ref.path}
                        author={comment.author}
                        context={""}
                        content={comment.comment}
                        created={comment.created}
                        parentId={comment.parent}
                        commentRef={comment.ref}
                        isHead={false}
                    />
                </div>
            )
        });
    }

    render() {
        return (
            <div
                className="describeThreadCard"
            >
                {this.displayHead()}
                {this.displayChildren()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const commentCreate = state.commentCreate;
    const selection = state.selection;
    const page = state.page;
    const user = state.user;
    const authData = state.authData
    const threadData = state.threadData;
    //console.log(threadData)
    return { commentCreate, user, authData, threadData };
};


export default connect(mapStateToProps, actions)(DescribeThreadCard);
