// Reducer that produces a OpenCards - an object containing the ids of open cards


const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'pane_expand':
            return true;
        case 'pane_collapse':
            return false;
        default:
            return state;
    }
};