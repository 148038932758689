const INITIAL_STATE = {
    threads: {},
    needsInit: true,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'fetch_threads_start':
            return action.payload
        case 'fetch_threads':
            Object.keys(action.payload).forEach(k => {
                let v = action.payload[k]
                if (!v.parent) {
                    return;
                }
                delete action.payload[k];
                action.payload[v.parent].replies[k] = v
            })

            return {threads: action.payload}
            // Deletes handle themselves because they trigger refetch.
        default:
            return state;
    }
};
