const INITIAL_STATE = {
    // Options: true -> button enabled, false -> disabled, str -> error message
    uiStatus: true
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'create_comment_start':
            return false;
        case 'create_comment_fail':
            return action.payload;
        case 'create_comment_success':
            return true;
        default:
            return true;
    }
};
