module.exports = `
<style class="scribe-inject">
    @import url("https://fonts.googleapis.com/css?family=Roboto");@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");@import url("https://fonts.googleapis.com/css?family=Overpass");@import url("https://fonts.googleapis.com/css?family=Arvo");@import url("https://use.typekit.net/tws3ibn.css");@font-face {
        font-family: "San Francisco";
        font-weight: 400;
        src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
    }

    @import url("https://use.typekit.net/tws3ibn.css");

    @font-face {
        font-family: "Noe Display";
        src: url(./assets/fonts/noe/noe-display-regular.woff);
    }

    @font-face {
        font-family: "Noe Display";
        src: url(./assets/fonts/noe/noe-display-bold.woff);
        font-weight: bold;
    }

    @font-face {
        font-family: "Sabon";
        src: url(./assets/fonts/sabon/Sabon-Roman.woff);
    }

    html, body {
        font-size: 20px;
        line-height: 34px;
        margin: 8px;
    }

    h1, h2, h3, h4 {
        text-align: left;
    }

    p, ol, ul, a {
        font-size: 20px;
        line-height: 34px;
    }

    h1 {
        font-size: 52px;
        line-height: 64px;
        margin-block-end: 0;
        margin-block-start: 0;
        margin-top: 0;
        font-weight: 900;
        font-style: normal;
        font-family: linotype-sabon;
    }

    h2, h3, h4, h5, h6 {
        font-size: 24px;
        line-height: 36px;
        margin-block-end: 0;
        margin-block-start: 0;
        margin-top: 0px;
        margin-bottom: 24px;
        padding-top: 24px;
    }

    blockquote {
        font-size: 24px;
        line-height: 36px;
        margin-block-end: 0;
        margin-block-start: 0;
        padding-top: 12px;
        margin-bottom: 36px;
        font-style: italic;
        margin-inline-start: 16px;
        margin-inline-end: 16px;
    }

    p {
        margin: 0;
        text-align: left;
        margin-bottom: 24px;
    }

    ol {
        margin: 0;
        text-align: left;
    }

    ul {
        margin: 0;
        text-align: left;
        padding-left: 0;
        padding-inline-start: 24px;
    }

    li {
        margin-bottom: 24px;
    }

    a {
        color: #373B40;
        text-decoration: underline;
        margin: 0;
        text-align: left;
        margin-bottom: 24px;
        transition: color .1s;
    }


    a:hover {
        color: #36B9E3;
    }


    code {
        color: #9BA5B1;
        margin: 8px;
        display: inline-block;
        max-width: calc(100% - 32px);
        font-size: 16px;
        overflow-x: scroll;
    }

    figcaption {
        text-align: center;
        font-style: italic;
        color: #9BA5B1;
        font-weight: 300;
        margin-bottom: 48px;
    }

    figure {
        margin-bottom: 24px;
    }

    .text_meta_date {
        margin-top: 16px;
        color: #9BA5B1;
        font-size: 16px;
    }

    .text_meta_url {
        color: #9BA5B1;
        font-size: 16px;
    }

    .doc_meta {
        padding-bottom: 8px;
        margin-bottom: 32px;
    }

    .text_meta {
        margin-bottom: 0;
    }

    .text-annotation {
        background-color: rgba(255, 246, 36, 0.25);
    }

    .text-annotation:hover {
        background-color: #FFE70D;
    }

    .text-annotation-hover {
        background-color: #FFE70D;
    }

    .text-download {
        font-size: 12px;
        font-weight: bold;
        line-height: 21px;
        color: #36B9E3;
        padding-left: 16px;
        padding-right: 16px;
    }

    .text-highlight-open {
        float: left;
    }

    @media screen and (max-width: 480px) {
        html, body {
            max-width: 100%;
            font-size: 16px;
            line-height: 28px;
        }

        h1 {
            font-size: 36px;
            line-height: 48px;
        }

        h2, h3, h4, h5, h6 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 18px;
            padding-top: 16px;
            padding-bottom: 8px;
        }

        blockquote {
            font-size: 18px;
            line-height: 27px;
            margin-top: 0px;
            margin-bottom: 27px;
        }

        p, ol, ul {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 24px;
        }

        .annotationCard {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 24px;
            font-weight: normal;
            font-style: normal;
        }

        a {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 24px;
        }

        figure {
            margin-bottom: 24px;
        }

        .text_meta_date {
            font-size: 12px;
            line-height: 18px;
            padding-bottom: 4px;
            font-style: normal;
        }

        .text_meta_url {
            font-size: 12px;
            line-height: 18px;
            margin-top: 4px;
        }
    }

    .annotationMobile {
        display: none;
    }

    .annotationCard {
        float: left;
        display: flex;
        flex-direction: row;
        width: 20rem;
    }

    .annotationProfileHolder {
        margin-top: -16px;
    }

    .annotationProfile {
        width: 32px;
        min-width: 32px;
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 16px;
        margin-top: -16px;
    }

    .annotationContent {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: -44px;
        background-color: #f8f8f8;
        border-style: solid;
        border-color: #373B40;
        border-radius: 16px;
        border-width: 1px;
        margin-right: 8px;
        max-width: calc(100% - 48px);
    }

    .annotationBar {
        margin-top: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .annotationAuthor {
        font-weight: bold;
        margin-bottom: 8px;
        margin-left: 16px;
        font-size: 16px;
        line-height: 24px;
    }

    .annotationText {
        margin-bottom: 8px;
        margin-right: 16px;
        margin-left: 16px;
        word-wrap: break-word;
        max-width: 100%;
        font-size: 16px;
        line-height: 24px;
    }

    .annotationTime {
        color: #9BA5B1;
        font-style: italic;
        margin-right: 16px;
        margin-left: 16px;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
    }

    ::placeholder {
        color: #9BA5B1;
    }

    textarea:focus, textarea:active {
        outline: none;
    }

    .takeaway {
        border-style: solid;
        border-color: #373B40;
        border-radius: 16px;
        padding: 16px;
        font-size: 16px;
        border-width: 1px;
        margin: 8px;
        width: calc(100% - 16px);
        height: 96px;
        background: #f8f8f8;
    }

    @media screen and (max-width: 480px) {
        .annotationDesktop {
            display: none;
        }

        .annotationMobile {
            float: left;
            width: 100%;
            display: block;
        }

        .annotationCard {
            width: 100%;
            max-width: 100%;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .annotationContent {
            margin-top: 0px;
        }

        .annotationProfileHolder {
            margin-top: 8px;
        }

        .annotationProfile {
            width: 24px;
            min-width: 24px;
            margin-left: 8px;
            margin-right: 8px;
            border-radius: 12px;
            margin-top: 8px;
        }
    }

    .doc_page {
        margin-top: 96px;
    }

    .doc_bar {
        height: 64px;
        background-color: rgba(248, 248, 248, 0.95);
        position: fixed;
        top: 0;
        margin-left: -32px;
        margin-right: -32px;
        width: calc(100% + 64px);
    }

    .bottom_bar {
        height: 84px;
        border-top: 0px solid #373B40;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 84px;
        margin-left: -32px;
        margin-right: -32px;
        width: calc(100% + 64px);
    }

    .bottom_bar_inner {
        margin-left: -32px;
        margin-right: -32px;
        width: calc(100% + 64px);
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .doc_bar_icons {
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 0px;
        margin-right: 8px;
    }

    .bottom_bar_icons {
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 0px;
        margin-right: 8px;
    }

    .doc_bar_download {
        display: flex;
        flex-direction: row;
        height: 20px;
        border-radius: 12px;
        border-style: solid;
        border-color: #36B9E3;
        border-width: 1px;
        justify-content: flex-start;
        margin-left: 8px;
    }

    .doc_bar_left {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    svg {
        fill: #373B40;
    }

    .icon_logo {
        width: 42px;
        height: 42px;
    }

    .banner_logo {
        width: 168px;
        height: 42px;
    }

    @media screen and (max-width: 480px) {
        .doc_page {
            margin-top: 16px;
        }

        .doc_bar {
            height: 48px;
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
        }

        .bottom_bar {
            height: 64px;
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
        }

        .bottom_bar_inner {
            margin-left: 0px;
            margin-right: 0px;
            width: 100%;
        }

        .doc_bar_icons {
            height: 48px;
            margin-left: 8px;
            margin-right: 8px;
        }

        .bottom_bar_icons {
            height: 48px;
            margin-left: 0px;
            margin-right: 0px;
        }

        .icon_logo {
            width: 32px;
            height: 32px;
        }

        .banner_logo {
            width: 128px;
            height: 32px;
        }
    }

    .user_card_holder {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 24px;
        margin-left: -32px;
    }

    .user_profile {
        height: 56px;
        margin-right: 16px;
    }

    .user_profile_pic {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        margin: 0;
    }

    .user_card_text_top {
        margin-bottom: 0px;
        font-size: 16px;
        line-height: 24px;
    }

    .user_card_text_bottom {
        margin-bottom: 0px;
        font-size: 16px;
        line-height: 24px;
    }

    @media screen and (max-width: 480px) {
        .user_card_holder {
            margin-bottom: 16px;
            margin-left: 0;
        }

        .user_profile {
            height: 36px;
        }

        .user_profile_pic {
            width: 36px;
            height: 36px;
            border-radius: 18px;
        }

        .user_card_text_top {
            font-size: 12px;
            line-height: 18px;
        }

        .user_card_text_bottom {
            font-size: 12px;
            line-height: 18px;
        }
    }

    html, body {
        font-family: adelle-sans, sans-serif;
        background: #f8f8f8;
        color: #373B40;
    }

    .marginControl {
        margin-left: 32px;
        margin-right: 32px;
    }

    .page {
        padding: 0;
    }

    figure {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-content: center;
    }

    picture {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-height: 60vh;
    }

    img {
        padding: 0;
        margin-top: 32px;
        margin-bottom: 48px;
        margin-left: -32px;
        margin-right: -32px;
        width: calc(100% + 64px);
        height: auto;
    }

    p > img {
        margin-bottom: 0px;
    }

    .imageCover {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: -32px;
        margin-right: -32px;
        width: calc(100% + 64px);
    }

    figure {
        margin-left: 0;
        margin-right: 0;
    }

    section {
        margin-bottom: 24px;
    }

    iframe {
        max-width: 100%;
    }

    hr {
        display: none;
    }

    .blacklogo {
        fill: #373B40;
    }

    .whitelogo {
        fill: #f8f8f8;
    }

    .AppHolder {
        display: flex;
        flex-direction: row;
        width: 60rem;
        margin-left: auto;
        margin-right: auto;
    }

    .contentFrame {
        position: relative;
        width: 60rem;
        margin-left: auto;
        margin-right: auto;
    }

    .appFrame {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 60rem;
        margin-left: auto;
        margin-right: auto;
    }

    .loadingFrame {
        width: 100%;
        margin-left: -64px;
        margin-right: -64px;
        height: 100vh;
        position: absolute;
        background: #f8f8f8;
    }

    .App {
        width: 40rem;
    }

    .Sidebar {
        width: 20rem;
    }

    .testBox {
        float: left;
        width: 100%;
        height: 0px;
        background-color: red;
    }

    ::placeholder {
        color: #9BA5B1;
    }

    textarea:focus, textarea:active {
        outline: none;
    }

    .takeaway {
        border-style: solid;
        border-color: #373B40;
        border-radius: 16px;
        padding: 16px;
        margin: 0;
        font-size: 16px;
        border-width: 1px;
        width: calc(100% - 32px);
        height: 96px;
        background: #f8f8f8;
    }

    @media screen and (max-width: 480px) {
        .marginControl {
            margin-left: 16px;
            margin-right: 16px;
        }

        .AppHolder {
            width: 100%;
        }

        .contentFrame {
            width: 100%;
        }

        .appFrame {
            width: 100%;
        }

        .Sidebar {
            display: none;
        }

        .page {
            padding: 0;
        }

        .App {
            width: 100%;
        }

        img {
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
        }

        .imageCover {
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
        }
    }

    /*# sourceMappingURL=App.css.map */
</style>
<div class="doc_meta">
    <p class="text_meta text_meta_date">Nov 12, 2018</p>
    <h1 class="text_meta text_meta_title">How to replace your significant other with an inanimate object</h1>
    <p class="text_meta text_meta_url">Ben Mann     </p>
</div>
<div id="readability-page-1" class="page">
    <div data-post-id="df6881010c70" data-source="post_page" data-tracking-context="postPage" data-scroll="native">
        <section name="dacf">
            <div>
                <div>
                    <figure name="b239" id="b239">
                        <div>
                            <p>
                                <img data-image-id="1*20YkLEUGv0Qx29E1JUhiyQ.jpeg" data-width="3995" data-height="1704" data-action="zoom" data-action-value="1*20YkLEUGv0Qx29E1JUhiyQ.jpeg" src="https://cdn-images-1.medium.com/max/2000/1*20YkLEUGv0Qx29E1JUhiyQ.jpeg">
                            </p>
                        </div>
                    </figure>
                </div>
                <div>
                    <p name="ac5c" id="ac5c">Let’s approach a romantic relationship from first principles. What are we getting out of it?</p>
                    <p name="3ae6" id="3ae6">
                        According to the School of Life, <a href="https://www.youtube.com/watch?v=bw-_iPIcGIU" rel="nofollow" target="_blank">love is</a>
                        :

                    </p>
                    <ul>
                        <li name="c7cc" id="c7cc">Charity</li>
                        <li name="42ea" id="42ea">Imagination</li>
                        <li name="4832" id="4832">Kindness</li>
                        <li name="2aad" id="2aad">Forgiveness</li>
                        <li name="0ea7" id="0ea7">Loyalty</li>
                        <li name="b26d" id="b26d">Generosity</li>
                        <li name="75c1" id="75c1">Patience</li>
                    </ul>
                    <p name="a4d0" id="a4d0">
                        We can get all of these out of a good friendship, though. School of Life explains in a separate video: <a href="https://www.youtube.com/watch?v=jltM5qYn25w" rel="nofollow" target="_blank">Romanticism</a>
                        elevated sex and touch to the ultimate expression of romantic love.&nbsp;
                    </p>
                    <p name="9db0" id="9db0">What would it look like if we dissociated these things? Could we meet our psychological needs through platonic friendships, and our physical needs in some other way? Do our minds distinguish between animate and inanimate touch?</p>
                    <h4 name="583d" id="583d">Whence touch</h4>
                    <p name="f6ac" id="f6ac">I conducted a literature review (Google Scholar + sci-hub) to find evidence that touch has a positive impact on psychological metrics, and whether inanimate touch could be as effective. A few hours of searching didn’t yield much, but I’ve included my notes in an appendix below.</p>
                    <p name="5f2d" id="5f2d">Given the lack of guiding research, it seems reasonable to proceed on intuition. Hypothesis: touching something that lets us imagine animacy on a regular basis could have the positive effects we’re looking for.</p>
                    <h4 name="dec8" id="dec8">Go go gadget engineering</h4>
                    <p name="6f75" id="6f75">One study (the big table in the appendix) shows two activities are correlated with relationship satisfaction and also vulnerable to automation: massage and cuddling/holding.&nbsp;</p>
                    <p name="091f" id="091f">
                        I recently came across a <a href="https://thewirecutter.com/reviews/best-body-pillow/" rel="nofollow" target="_blank">Wirecutter review of the best body pillow</a>
                        . They argue body pillows make sleeping more ergonomic for side sleepers. When I looked at the pictures, I saw the body pillow as an inanimate <a href="https://www.urbandictionary.com/define.php?term=Little%20spoon" rel="nofollow" target="_blank">little spoon</a>
                        . But how could I make the experience feel more like hugging a dog or a human? I considered taking apart a massage chair and shoving it inside the pillow, but that seemed unwieldy.

                    </p>
                    <p name="d3a2" id="d3a2">
                        When I considered what cover to buy for the pillow, I realized I could craft <a href="https://thewirecutter.com/reviews/best-electric-blanket-and-heated-mattress-pad/" rel="nofollow" target="_blank">Wirecutter’s electric blanket</a>
                        into a pillow case and approximate warmth too!

                    </p>
                    <p name="9283" id="9283">
                        I ordered <a href="https://www.amazon.com/gp/product/B011U1LIK8/" rel="nofollow" target="_blank">this body pillow</a>
                        for $70 and <a href="https://www.amazon.com/gp/product/B01LZJUDYQ/" rel="nofollow" target="_blank">this heated throw</a>
                        for $46. At first, I wasn’t sure if I would enjoy using a body pillow at all, let a lone a heated one. For the first two weeks, I wrapped the pillow in the blanket and used it most nights. Together, they weigh 10 pounds. That quick test convinced me there’d be enough value to justify the effort to sew it up.

                    </p>
                    <p name="6e83" id="6e83">I seam ripped the blanket where I planned to sew it back together. Then I put 4 layers of plush and 4 layers of liner together under my sewing machine foot and put pedal to metal. It barely handled the depth but in the end it came out looking good.</p>
                    <figure name="389a" id="389a">
                        <div>
                            <p>
                                <img data-image-id="1*rHwrigNvsHyZqmevPSHJqA.jpeg" data-width="4048" data-height="2096" data-action="zoom" data-action-value="1*rHwrigNvsHyZqmevPSHJqA.jpeg" src="https://cdn-images-1.medium.com/max/1600/1*rHwrigNvsHyZqmevPSHJqA.jpeg">
                            </p>
                        </div>
                    </figure>
                    <p name="4f3b" id="4f3b">&nbsp;I’ve been using it for the past few weeks. Some of the highlights compared to a human:</p>
                    <ul>
                        <li name="35f4" id="35f4">It’s always cold at night in San Francisco. Turning the blanket up to medium cures my icy feet instantly.</li>
                        <li name="5a48" id="5a48">My arm under the pillow doesn’t fall asleep.</li>
                        <li name="a123" id="a123">It doesn’t move around after I fall asleep, and it doesn’t care when I do.</li>
                        <li name="76f9" id="76f9">It doesn’t feel neglected when I roll away.</li>
                        <li name="8896" id="8896">No bones.</li>
                    </ul>
                    <p name="cfd7" id="cfd7">There’s something about the size and warmth that makes it feel qualitatively different than a simple pillow. Simulated survey responses on a 5 point Likert scale:</p>
                    <ul>
                        <li name="41b2" id="41b2">“I enjoy using it” → strongly agree.&nbsp;</li>
                        <li name="c029" id="c029">“I would recommend it to a friend” → agree if single, neutral otherwise.</li>
                        <li name="ca2c" id="ca2c">“I’m embarrassed when my friends see it” → agree, but here I am writing this post</li>
                    </ul>
                    <h4 name="02bc" id="02bc">Cuddlebot v2</h4>
                    <p name="fb87" id="fb87">
                        Based on the research, the next iteration involves attaching a <a href="http://a.co/d/fUnHnoW" rel="nofollow" target="_blank">massage piece</a>
                        , but that runs at cross purposes with how I typically use it: while I’m falling asleep. It provides some physical and emotional comfort, but perhaps it could be even better.

                    </p>
                    <p name="069d" id="069d">How do we fool our sleeping brain into thinking that the pillow is a person? The answer might include weight, a heartbeat, breathing, and regulated temperature. Or maybe that’s the answer to, “How do you make a pillow creepy?” Friends suggested changing the materials to be softer and heavier to be more like a pet. Some possibilities:</p>
                    <ul>
                        <li name="c4ee" id="c4ee">
                            Faux fur like <a href="http://a.co/d/f7hZFxv" rel="nofollow" target="_blank">sheepskin</a>
                            or <a href="http://a.co/d/2IddQsp" rel="nofollow" target="_blank">husky</a>
                            — will definitely require a <a href="http://a.co/d/9YvT33i" rel="nofollow" target="_blank">walking foot</a>
                            , machine washing could be tricky, but this could go a long way to reducing jealousy: “Don’t worry honey, it’s like a dog, not a person.”

                        </li>
                        <li name="d681" id="d681">Add weight — most weighted blankets use plastic pellets, but why not use a core made of sand, lentils, or gel packs?</li>
                        <li name="5d4e" id="5d4e">Add some kind of arms — while this would increase the structural complexity, some people much prefer being little spoon. Instead of actually attaching the arms, we could use a long thin pillow that wraps around the main one, which would make the cover simpler while still providing leverage.</li>
                        <li name="eab4" id="eab4">Embed the heating element in the pillow itself — when I seam ripped the blanket, it looked like the heating wire could easily be removed and retrofitted elsewhere. This would make cleaning the cover easier.</li>
                    </ul>
                    <p name="baf9" id="baf9">Want me to make you one of these? What features are important? Let me know in the comments!</p>
                </div>
            </div>
        </section>
        <section name="a752">
            <div>
                <div>
                    <h4 name="cf33" id="cf33">Appendix: Whence &nbsp;touch</h4>
                    <p name="f67f" id="f67f">I conducted a literature review (Google Scholar + sci-hub) to find evidence that touch has a positive impact on psychological metrics, and whether inanimate touch could be as effective. A few hours of searching didn’t yield much. Here are the highlights.</p>
                    <p name="0a31" id="0a31">
                        In <a href="https://www.autonomicneuroscience.com/article/0165-1838(95)00056-7/abstract" rel="noopener nofollow" target="_blank">1995</a>
                        researchers found that massaging rats’ tummies while intubated under general anesthesia lowered their blood pressure through oxytocin release.

                    </p>
                    <p name="3eb1" id="3eb1">
                        Stuffed animals make old people feel better. A <a href="https://sci-hub.tw/https://www.thieme-connect.com/products/ejournals/abstract/10.1055/s-2001-17427" rel="noopener nofollow" target="_blank">2001</a>
                        review of Alzheimer’s interventions noted

                    </p>
                    <blockquote name="2002" id="2002">
                        <a href="http://dx.doi.org/10.1016/S0197-4572(86)80034-9" rel="noopener nofollow" target="_blank">Francis and Baly</a>
                        [1986] investigated the effect of providing plush animals to nursing home residents. The 22 residents, each of whom was given a stuffed animal for 8 weeks, showed significant improvements on measures of life satisfaction, depression, psychosocial function, social competence, and personal neatness. The 18 residents who did not receive a plush animal showed no change in any of these variables.

                    </blockquote>
                    <p name="9098" id="9098">I speculate that it being a stuffed animal and not a characterless pillow was important.</p>
                    <p name="0622" id="0622">
                        A <a href="https://www.tandfonline.com/doi/abs/10.1080/01926180390201936" rel="noopener nofollow" target="_blank">2003</a>
                        study of 295 college students investigated the relationship between a bunch of relationship outcomes and physical affection (PA) inputs. When I first read the study I couldn’t understand why they’d exclude sex from the list. Then I realized they were Mormons. In the chart below, the first two columns are about the relationship (self to other) while the second two are about the partner (other only).

                    </p>
                    <figure name="a32e" id="a32e">
                        <div>
                            <p>
                                <img data-image-id="1*rs5KjMkvL5coWgxFmTBopg.png" data-width="1498" data-height="1254" data-action="zoom" data-action-value="1*rs5KjMkvL5coWgxFmTBopg.png" src="https://cdn-images-1.medium.com/max/1600/1*rs5KjMkvL5coWgxFmTBopg.png">
                            </p>
                        </div>
                    </figure>
                    <p name="7e8c" id="7e8c">For some reason estimation of partner satisfaction is most affected by massage and cuddling, whereas self satisfaction is more affected by massage and kissing.</p>
                    <p name="8a45" id="8a45">
                        Our brains distinguish visually what kind of touch we’re getting. In this <a href="https://www.mitpressjournals.org/doi/abs/10.1162/jocn.2008.20111" rel="noopener nofollow" target="_blank">2008</a>
                        study, fMRI shows brains (N=15) distinguish degree of intentionality of touch:

                    </p>
                    <blockquote name="b2af" id="b2af">Activation… significantly correlated with the degree of intentionality of the observed touch stimuli as rated by the participants… the activation… might reflect automatic simulation of proprioceptive aspects related to observed touch when intentionality is assumed by the observer</blockquote>
                    <p name="1cd6" id="1cd6">However the other activated areas were identical between intentional, accidental, animate, and inanimate touch. My reading of this says when we see intentional hand movements, our brains automatically simulate them, but how we interpret the hand movements in other parts of the brain are identical. If our eyes are closed, maybe there’s no difference between different kinds of touch.</p>
                    <p name="e926" id="e926">
                        A <a href="https://www.ncbi.nlm.nih.gov/pubmed/18992276" rel="noopener nofollow" target="_blank">2008 review</a>
                        concluded

                    </p>
                    <blockquote name="4c1b" id="4c1b">We believe that even the most advanced devices will not be able to deliver something that can approximate to realistic interpersonal touch if we do not know in the first instance what needs to be communicated and how to communicate it. Specifically, what is the role played respectively by caloric, kinaesthetic, proprioceptive, motion, and vibratory cues in informing us that another human being is touching our skin/body?</blockquote>
                    <p name="8a55" id="8a55">So as of 10 years ago, we were still only scratching the surface, physiologically, of what it means for one person to touch another!</p>
                    <p name="2aeb" id="2aeb">
                        A <a href="https://www.sciencedirect.com/science/article/pii/S0001691808001480" rel="noopener nofollow" target="_blank">2009</a>
                        study showed people (N=13) prefer the feel of others’ skin to their own.

                    </p>
                    <p name="67d8" id="67d8">
                        A <a href="https://www.sciencedirect.com/science/article/pii/S0273229711000025" rel="noopener nofollow" target="_blank">2011 review</a>
                        confirms the Mormon study that massage is one of the most effective forms of touch when evaluated for positive measurable outcomes.

                    </p>
                </div>
            </div>
        </section>
        <section name="232e">
            <div>
                <p name="309c" id="309c">
                    <em>Like this? </em>
                    <a href="https://tinyletter.com/benjmann" rel="nofollow" target="_blank">
                        <em>Subscribe</em>
                    </a>
                    <em>to my monthly newsletter for more!</em>
                </p>
            </div>
        </section>
    </div>
</div>
`;
