import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import '../App.css';

import threadData from "../data/threadData.json";
import ProgressThreadIcon from './ProgressThreadIcon';

//Bar on the right side overlaid on content displaying relative thread locations. 
class ProgressMap extends Component {

    componentWillMount() {
    }

    componentDidMount() {
        //load json
        //enumerate anchor ids
        //for each, create array anchorId: offset
        //send array to redux
        this.enumerateAnchors();
    }

    //get position of element
    getOffset(el) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    enumerateAnchors() {
        var docHeight = (document.height !== undefined) ? document.height : document.body.offsetHeight;
        var anchorPositions = {};
        threadData.forEach((element) => {
            var offset = this.getOffset(document.getElementById(element.anchorId));
            var elementY = offset.top; 
            var elementRawX = offset.left;
            elementY = elementY / docHeight * 100;
            anchorPositions[element.anchorId] = {"name": element.anchorId, "position": elementY, "horizontal": elementRawX };
        });
        this.props.updateAnchors(anchorPositions);
    }

    render() {
        var percent = this.props.scrollPercent.scrollPercent+"vh";
        return (
            <div className="progressMapBar">
                <div className="progressTick" style={{ top: percent }}>
                </div>
                {_.map(this.props.anchorPositions, anchor => {
                    return <ProgressThreadIcon anchor={anchor} key={anchor.name} />
                    //if (thread.openState) return <ThreadCard thread={thread} position={thread.position} key={Math.random()}/>
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const scrollPercent = state.scrollPercent;
    const anchorPositions = state.anchorPositions.anchorPositions;
    return { scrollPercent, anchorPositions };
};

export default connect(mapStateToProps, actions)(ProgressMap);