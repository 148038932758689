export const expandPane = () => {
    return {
        type: "pane_expand",
        payload: null
    }
}

export const collapsePane = () => {
    return {
        type: "pane_collapse",
        payload: null
    }
}

export const updateDescribeSelectionExists = () => {
    return {
        type: "update_describe_selection_exists",
        payload: null
    }
}

export const updateDescribeSelection = (selection) => {
    console.log(selection);
    return {
        type: "update_describe_selection",
        payload: selection
    }
}

export const closeDescribeSelection = () => {
    return {
        type: "close_describe_selection",
        payload: null
    }
}